import type { Tracker } from '@yoursurprise/segment-analytics';
import { window } from '../../globals';
import { isArray } from '../../typeguards';
import { OPTIMIZELY_SEGMENT_EVENT } from '../types';
import tracker from '../../../react/general/Tracker/tracker';

export default class OptimizelyEvents {
    public static pushActivateLifecycleListener() {
        if (!window) {
            return;
        }

        window.optimizely = window.optimizely || [];
        window.optimizely.push({
            filter: {
                name: 'activated',
                type: 'lifecycle',
            },
            handler: () => {
                this.trackExperiments(tracker);
            },
            type: 'addListener',
        });
    }

    // AB test YSP24012
    public static pushOptOutNewsletterCheckout() {
        if (!window) {
            return;
        }

        window.optimizely = window.optimizely || [];
        window.optimizely?.push({
            eventName: 'OptOutNewsletterCheckout',
            type: 'event',
        });
    }

    // AB test YSP24012
    public static pushOptInNewsletterCheckout() {
        if (!window) {
            return;
        }

        window.optimizely = window.optimizely || [];
        window.optimizely?.push({
            eventName: 'OptInNewsletterCheckout',
            type: 'event',
        });
    }

    private static trackExperiments(segmentTracker: Tracker) {
        const optimizely = window?.optimizely;
        const siteMetadata = window?.siteMetadata;

        if (!optimizely || isArray(optimizely)) return;

        const activeExperiments = [];

        if (siteMetadata?.experimentVariation) {
            activeExperiments.push({
                experimentId: siteMetadata.experimentFlag,
                experimentName: siteMetadata.experimentFlag,
                variationId: siteMetadata.experimentVariation,
                variationName: siteMetadata.experimentVariation,
            });
        }

        const activeCampaigns = optimizely.get('state').getCampaignStates({ isActive: true });

        Object.values(activeCampaigns || {}).forEach((campaign) => {
            activeExperiments.push({
                experimentId: campaign.experiment.id,
                experimentName: campaign.experiment.name,
                variationId: campaign.variation.id,
                variationName: campaign.variation.name,
            });
        });

        if (activeExperiments.length) {
            segmentTracker.track(OPTIMIZELY_SEGMENT_EVENT, { experiments: activeExperiments });
        }
    }
}
