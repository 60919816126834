import { window } from '../../globals';

export default class OptimizelyAttributes {
    public static optimizelyOnLoadAttributes() {
        if (!window) {
            return;
        }
        window.optimizely = window.optimizely || [];
        OptimizelyAttributes.pushSiteAttribute();
    }

    private static pushSiteAttribute() {
        if (!window || !window.siteMetadata.siteUrl) {
            return;
        }

        window.optimizely?.push({
            attributes: {
                site: window.siteMetadata.siteUrl,
            },
            type: 'user',
        });
    }
}
