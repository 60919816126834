export const OPTIMIZELY_COOKIE_UUID_NAME = 'ysp_optimizely_uuid';

export const OPTIMIZELY_SEGMENT_EVENT = 'Page Experiments Viewed';

export type OptimizelyClient = {
    get: (state: string) => {
        getCampaignStates: (config : { isActive: boolean }) => Record<string, {
            experiment: {
                id: string;
                name: string;
            };
            variation: {
                id: string;
                name: string;
            };
        }>;
    };
    initialized?: boolean;
    push: (event: Record<string, unknown>) => void;
};
